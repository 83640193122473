import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { useLazyQuery } from "@apollo/react-hooks"
// import { Router } from "@reach/router"

import { FIND_PROPERTY } from "../../../graphql/development-graphql"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { HeadingBasic } from "../../../components/heading/heading"
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { FormSML } from "../../../components/forms-sml/forms-sml"
// import ProjectDetail from "../../../templates/project-detail"
import { ProjectsByLocation } from "../../../components/utils/render-functions"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const RetailPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgBannerResidence: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgRetailFinder: file(relativePath: { eq: "retail/retail-finder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        propertyType(id: "retail", idType: SLUG) {
          name
          description
          acfPropertyType {
            image {
              sourceUrl
            }
            excerpt
            banner {
              sourceUrl
            }
          }
          translation(language: ID) {
            name
            description
            acfPropertyType {
              image {
                sourceUrl
              }
              excerpt
              banner {
                sourceUrl
              }
            }
          }
          projects(first: 1000) {
            nodes {
              id
              projectTags {
                edges {
                  node {
                    name
                  }
                }
              }
              title
              slug
              excerpt
              locations {
                nodes {
                  name
                }
              }
              propertyTypes {
                nodes {
                  name
                }
              }
              featuredImage {
                sourceUrl
                caption
                mediaDetails {
                  sizes {
                    name
                    width
                    height
                    file
                    sourceUrl
                  }
                }
              }
              translation(language: ID) {
                # id
                projectTags {
                  edges {
                    node {
                      name
                    }
                  }
                }
                title
                slug
                excerpt
                locations {
                  nodes {
                    name
                  }
                }
                propertyTypes {
                  nodes {
                    name
                  }
                }
                featuredImage {
                  sourceUrl
                  caption
                  mediaDetails {
                    sizes {
                      name
                      width
                      height
                      file
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
        facilities(first: 100) {
          nodes {
            name
            slug
            facilityId
            projects {
              nodes {
                propertyTypes {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
          }
        }

        locations(first: 100) {
          nodes {
            locationId
            slug
            name
          }
        }

        propertyTypes {
          nodes {
            name
            slug
            propertyTypeId
          }
        }
      }
    }
  `)

  const [findProperty, setFindProperty] = useState({
    location: null,
    propertyTypes: null,
    projects: null,
    checkBoxValues: [],
  })

  const [
    getProperty,
    { data: properties, loading: loadingProperties },
  ] = useLazyQuery(FIND_PROPERTY)

  const wordPress = data?.wordPress?.propertyType
  const projects = data.wordPress?.propertyType?.projects?.nodes
  const locations = []
  data.wordPress.locations.nodes.map(item =>
    locations.push({ value: item.slug, label: item.name })
  )
  let propertyTypes = []
  data.wordPress.propertyTypes.nodes.map(item =>
    propertyTypes.push({ value: item.slug, label: item.name })
  )
  let listProperties = []
  properties &&
    properties.projects.edges.map(item => {
      listProperties.push({ label: item.node.title, value: item.node.slug })
    })
  const listPropertyTypes = data.wordPress.propertyTypes.nodes
  const listFacilities = data.wordPress.facilities.nodes
  const listLocations = data.wordPress.locations.nodes

  useEffect(() => {
    let facilities = []
    let locations = []
    let propertyTypeId =
      findProperty.propertyTypes &&
      listPropertyTypes.find(x => x.slug === findProperty.propertyTypes.value)
        .propertyTypeId

    findProperty.location &&
      listLocations.map(
        item =>
          item.name === findProperty.location.label &&
          locations.push(item.locationId)
      )

    findProperty.checkBoxValues.length > 0 &&
      findProperty.checkBoxValues.map(item => {
        listFacilities.map(facility => {
          if (facility.slug === item) {
            facilities.push(facility.facilityId)
          }
        })
      })

    if (locations.length > 0 && propertyTypeId) {
      getProperty({
        variables: {
          locationId: locations.length > 0 ? locations : null,
          typeId: [propertyTypeId],
          first: 1000,
          facilityId: facilities.length > 0 ? facilities : null,
        },
      })
    }
  }, [findProperty])

  const locationHandler = e => {
    setFindProperty({ ...findProperty, location: e })
  }

  const propertyTypeHandler = e => {
    setFindProperty({ ...findProperty, propertyTypes: e })
  }

  const projectsHandler = e => {
    setFindProperty({ ...findProperty, projects: e })
  }

  const checkBoxValuesHandler = e => {
    let checkBoxValuesTemp = findProperty.checkBoxValues
    e.checked && checkBoxValuesTemp.push(e.value)
    //prettier-ignore
    !e.checked && checkBoxValuesTemp.splice(checkBoxValuesTemp.indexOf(e.value), 1)
    setFindProperty({ ...findProperty, checkBoxValues: checkBoxValuesTemp })
  }

  const propertyFinderSubmit = e => {
    e.preventDefault()
    if (
      findProperty.location &&
      findProperty.propertyTypes &&
      !findProperty.projects
    ) {
      navigate("/search-result", {
        state: {
          propertyType: findProperty.propertyTypes.label,
          values: {
            location: findProperty.location,
            residenceFacilities: findProperty.checkBoxValues,
          },
        },
      })
    } else if (
      findProperty.location &&
      findProperty.propertyTypes &&
      findProperty.projects
    ) {
      navigate(
        `/development/${findProperty.propertyTypes.value}/${findProperty.projects.value}`
      )
    } else if (
      findProperty.location &&
      !findProperty.propertyTypes &&
      !findProperty.projects
    ) {
      navigate("/search-result", {
        state: {
          values: {
            location: findProperty.location,
          },
        },
      })
    } else if (
      !findProperty.location &&
      findProperty.propertyTypes &&
      !findProperty.projects
    ) {
      navigate("/search-result", {
        state: {
          propertyType: findProperty.propertyTypes.label,
        },
      })
    }
  }

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={wordPress && wordPress.name}
        fbTitle={wordPress && wordPress.name}
        twitterTitle={wordPress && wordPress.name}
        twitterDescription={wordPress && wordPress.description}
        fbDescription={wordPress && wordPress.description}
        description={wordPress && wordPress.description}
        url={location?.href}
        img={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
        twitterImg={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
        img={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={wordPress && wordPress.name}
        img={
          (wordPress && wordPress?.acfPropertyType?.banner?.sourceUrl) ||
          data?.imgCoverMain?.childImageSharp?.fluid
        }
        alt={wordPress && wordPress.name}
        className="cover-md cover-detail"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      <section className="py-main">
        <div className="container container-md">
          <HeadingBasic text={[(wordPress && wordPress.description) || ""]} />
        </div>
      </section>

      <section className="tab-section">
        <BackgroundImage
          Tag="div"
          fluid={data.imgRetailFinder.childImageSharp.fluid}
          className="tabs-header"
        >
          <div className="tabs-header-title">
            <div className="container mw-lg">
              <h3>Find the perfect property for you</h3>
            </div>
          </div>
          <div class="bg-overlay bg-overlay-additional" />
          <div class="bg-overlay bg-overlay-30" />
        </BackgroundImage>
      </section>
      <section className="tab-content-section">
        <div className="container mw-lg px-lg-up-0">
          <div className="tab-content-wrapper">
            <FormSML
              locations={locations}
              propertyTypes={propertyTypes}
              projects={listProperties}
              onCheckBoxChange={checkBoxValuesHandler}
              onLocationChange={locationHandler}
              onPropertyTypesChange={propertyTypeHandler}
              onProjectsChange={projectsHandler}
              onSubmit={propertyFinderSubmit}
              isLoading={loadingProperties}
            />
          </div>
        </div>
      </section>

      <ProjectsByLocation
        data={projects}
        location={location.pathname.replace(/\/$/, '')}
        lang="EN"
      />
    </Layout>
  )
}

// const Retail = ({ uri }) => (
//   <Router basepath="/development/retail">
//     <RetailPage path="/" location={uri} />
//     <ProjectDetail path="/:slug" />
//   </Router>
// )
export default RetailPage
